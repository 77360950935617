import React from "react";
import { Wrapper } from "../../css/landingSection";
import { useNavigate } from "react-router-dom";
import citrus from "../../images/landing/citrus.jpeg";

const CitrusLander = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/citrus");
	};

	return (
		<Wrapper>
			<div className="central-container">
				<div className="text-container">
					<p>The great Citrus family</p>
					<button className="explore-btn" onClick={handleClick}>
						Explore
					</button>
				</div>
				<div className="image-container">
					<img src={citrus} alt="citrus" />
					<div className="overlay"></div>
				</div>
			</div>
		</Wrapper>
	);
};

export default CitrusLander;
