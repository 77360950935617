import React from "react";
import { Wrapper } from "../../css/DisclaimerAndIntroStyle";

const Disclaimer = () => {
	return (
		<Wrapper style={{ paddingTop: "0", marginBottom: "10rem" }}>
			<p className="text">This website is a personal one-man's work, made from free resources graciously provided by trefle.io, pixabay.com and wikipedia.org</p>
		</Wrapper>
	);
};

export default Disclaimer;
