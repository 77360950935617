import styled from "styled-components";

export const Wrapper = styled.section`
	text-align: center;
	font-size: 4rem;
	margin: 0rem auto 0rem auto;
	padding-top: 10rem;
	.text {
		margin: 0 auto;
		max-width: 80%;
		color: var(--blue-7);
	}
	@media screen and (max-width: 1000px) {
		font-size: 3.5rem;
	}
	@media screen and (max-width: 800px) {
		font-size: 3rem;
	}
	@media screen and (max-width: 600px) {
		font-size: 2.5rem;
	}
`;
