import React from "react";
import styled from "styled-components";
import headerImg from "../../images/landing/forest.jpg";

const Wrapper = styled.article`
	.header-image-container {
		/* border: 2px solid black; */
		width: 100%;
		height: 40vh;
		.header-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
`;

const Header = () => {
	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header-image" className="header-image" />
			</div>
		</Wrapper>
	);
};

export default Header;
