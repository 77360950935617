import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.section`
	width: 100%;
	height: 8vh;
	background: linear-gradient(90deg, var(--blue-5) 0%, var(--green-5) 100%);
	/* border: 1px dashed orange; */
	font-size: 2.5rem;
	color: var(--gold-3);
	display: flex;
	align-items: center;
	padding-left: 4rem;
	a {
		color: inherit;
		/* border: 1px solid white; */
		padding: 0.5rem;
	}
	@media screen and (max-width: 800px) {
		font-size: 2.2rem;
	}
	@media screen and (max-width: 600px) {
		font-size: 2rem;
	}
	@media screen and (max-width: 500px) {
		font-size: 1.5rem;
	}
`;

const BreadCrumbs = () => {
	const location = useLocation();
	console.log(location);

	let secondSegment = "";
	let thirdSegment = "";

	if (location.pathname !== "/") {
		const splitedArray = location.pathname.split("/");
		secondSegment = splitedArray[1];
		thirdSegment = splitedArray[2];
		// secondSegment = location.pathname.substring(1);
		// thirdSegment = location.pathname.substring(2);
		console.log(secondSegment);
		console.log(thirdSegment);
	}

	return (
		<Wrapper>
			<a href="/">home</a>
			{secondSegment ? (
				<>
					<p>&#x3e;&#x3e;</p>
					<a href={`/${secondSegment}`}>{secondSegment}</a>
					{thirdSegment ? (
						<>
							<p>&#x3e;&#x3e;</p>
							<a href={`/${secondSegment}/${thirdSegment}`}>{thirdSegment}</a>
						</>
					) : null}
				</>
			) : (
				""
			)}
		</Wrapper>
	);
};

export default BreadCrumbs;
