import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import HeaderNav from "./HeaderNav";
import Footer from "./Footer";
import UpButton from "./UpButton";

const Wrapper = styled.div``;

const Layout = () => {
	return (
		<Wrapper>
			<div id="header-nav">
				<HeaderNav />
			</div>
			<div id="outlet">
				<Outlet />
			</div>
			<div id="upBtn">
				<UpButton />
			</div>
			<div id="footer">
				<Footer />
			</div>
		</Wrapper>
	);
};

export default Layout;
