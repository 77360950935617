import React from "react";
import styled from "styled-components";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.section`
	position: fixed;
	bottom: 50px;
	right: 10px;
	width: 50px;
	height: 50px;
	border-radius: 500px;
	display: grid;
	place-content: center;
	background-color: var(--green-5);
	cursor: pointer;
	opacity: 0.7;
	transition: all 0.3s ease-in-out;
	&:hover {
		opacity: 1;
	}
	.icon {
		color: var(--blue-8);
		font-size: 7rem;
	}
`;

const UpButton = () => {
	return (
		<Wrapper>
			<a href="#header-nav">
				<IoArrowUpCircleOutline className="icon" />
			</a>
		</Wrapper>
	);
};

export default UpButton;
