import React from "react";
import styled from "styled-components";
import Header from "./Header";
import RoseLander from "./RoseLander";
import Disclaimer from "./Disclaimer";
import CarnivorousLander from "./CarnivorousLander";
import SearchLander from "./SearchLander";
import BreadCrumbs from "../layout/BreadCrumbs";
import IntroText from "./IntroText";
import CitrusLander from "./CitrusLander";

const Wrapper = styled.section``;

const Landing = () => {
	return (
		<Wrapper>
			<Header />
			<BreadCrumbs />
			<IntroText />
			<CitrusLander />
			<SearchLander />
			<CarnivorousLander />
			<Disclaimer />
		</Wrapper>
	);
};

export default Landing;
