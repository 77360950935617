import React, { useEffect } from "react";
import headerImg from "../../images/pages/roses/rose_top.jpg";
import { rosesPageData } from "../../data";
import BreadCrumbs from "../layout/BreadCrumbs";
import { Wrapper } from "../../css/page";

const RosesPage = () => {
	useEffect(() => {
		document.getElementById("header-nav").scrollIntoView({ behavior: "instant" });
	}, []);

	// const boldingTextMatcher = (match, text) => {
	// 	let regExp = new RegExp();
	// 	regExp = new RegExp(match, "gi");
	// 	return text.replace(regExp, <strong>${match}</strong>);
	// };

	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header" className="header-image" />
			</div>
			<BreadCrumbs />
			<div className="body-container">
				<div className="central-grid">
					<div className="cell cell1">
						<div className="cell-text">{rosesPageData[0].text}</div>
						{/* <div className="cell-text">{boldingTextMatcher("black baccara", rosesPageData[0].text)}</div> */}
						<div className="cell-image">
							<img src={rosesPageData[0].url} alt="" className="img" />
							<p className="img-title">{rosesPageData[0].title}</p>
						</div>
					</div>
					<div className="cell cell2">
						<div className="cell-image">
							<img src={rosesPageData[1].url} alt="" className="img" />
							<p className="img-title">{rosesPageData[1].title}</p>
						</div>
						<div className="cell-text">{rosesPageData[1].text}</div>
					</div>
					<div className="cell cell3">
						<div className="cell-text">{rosesPageData[2].text}</div>
						<div className="cell-image">
							<img src={rosesPageData[2].url} alt="" className="img" />
							<p className="img-title">{rosesPageData[2].title}</p>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default RosesPage;
