import React from "react";
import { Wrapper } from "../../css/landingSection";
import carnivorous from "../../images/landing/pitcher-plant-651418_1920.jpg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const SubWrapper = styled.div`
	left: 70% !important;
	@media screen and (max-width: 800px) {
		left: 60% !important;
	}
`;

const CarnivorousLander = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/carnivorous");
	};

	return (
		<Wrapper>
			<div className="central-container">
				<SubWrapper className="text-container">
					<p>The most frightful carnivorous plants on earth</p>
					<button className="explore-btn" onClick={handleClick}>
						Explore
					</button>
				</SubWrapper>
				<div className="image-container">
					<img src={carnivorous} alt="rose" style={{ objectPosition: "center" }} />
					<div className="overlay" style={{ background: "linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))" }}></div>
				</div>
			</div>
		</Wrapper>
	);
};

export default CarnivorousLander;
