import React, { useEffect } from "react";
import BreadCrumbs from "../layout/BreadCrumbs";
import { Wrapper } from "../../css/page";
import headerImg from "../../images/landing/pitcher-plant-651418_1920.jpg";
import { carnivoresPageData } from "../../data";
import { useNavigate } from "react-router-dom";

const CarnivorousPage = () => {
	const navigate = useNavigate();

	const handleNavigate = (target) => {
		console.log("Navigate");
		navigate(target);
	};

	useEffect(() => {
		document.getElementById("header-nav").scrollIntoView({ behavior: "instant" });
	}, []);

	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header" className="header-image" />
			</div>
			<BreadCrumbs />
			<div className="body-container">
				<div className="central-grid">
					<div className="cell cell1">
						<div className="cell-text">{carnivoresPageData[0].text}</div>
						<div className="cell-image" onClick={() => handleNavigate(carnivoresPageData[0].innerLink)}>
							<img src={carnivoresPageData[0].url} alt="" className="img" />
							<p className="img-title">{carnivoresPageData[0].title}</p>
						</div>
					</div>
					<div className="cell cell2">
						<div className="cell-image" onClick={() => handleNavigate(carnivoresPageData[1].innerLink)}>
							<img src={carnivoresPageData[1].url} alt="" className="img" />
							<p className="img-title">{carnivoresPageData[1].title}</p>
						</div>
						<div className="cell-text">{carnivoresPageData[1].text}</div>
					</div>
					<div className="cell cell3">
						<div className="cell-text">{carnivoresPageData[2].text}</div>
						<div className="cell-image" onClick={() => handleNavigate(carnivoresPageData[2].innerLink)}>
							<img src={carnivoresPageData[2].url} alt="" className="img" />
							<p className="img-title">{carnivoresPageData[2].title}</p>
						</div>
					</div>
				</div>
			</div>{" "}
		</Wrapper>
	);
};

export default CarnivorousPage;
