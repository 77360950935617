import styled from "styled-components";

export const Wrapper = styled.section`
	margin: 10rem auto;
	width: 100%;
	height: 400px;
	/* border: 1px solid red; */
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
	.text-container {
		z-index: 2;
		position: absolute;
		top: 40%;
		left: 10%;
		font-size: 3rem;
		color: var(--gold-2);
		max-width: 400px;
		line-height: 1.5;
		.text {
		}
		.explore-btn {
			position: relative;
			text-transform: uppercase;
			letter-spacing: 0.5rem;
			padding: 1rem 2rem;
			background-color: var(--blue-5);
			color: var(--gold-4);
			border: 1px solid var(--gold-2);
			font-family: inherit;
			margin: 0 auto;
			cursor: pointer;
			/* border-radius: 6px; */
			transition: 0.5s;
			z-index: 1;
		}
		.explore-btn::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: var(--green-7);
			transform-origin: right;
			transition: all 0.5s ease;
			transform: scaleX(0);
			z-index: -1;
			/* border-radius: 6px; */
		}
		.explore-btn:hover {
			/* color: var(--gold-7); */
			&::after {
				transform-origin: right;
				transform: scaleX(1);
			}
		}
	}
	.image-container {
		height: 100%;
		width: 100%;
		position: absolute;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
		}
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
		}
	}
	@media screen and (max-width: 1000px) {
		.text-container {
			font-size: 2.5rem;
		}
	}
	@media screen and (max-width: 600px) {
		.text-container {
			font-size: 2rem;
		}
	}
`;
