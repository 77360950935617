import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PlantDetails from "../sub-components/PlantDetails";
import headerImg from "../../images/pages/plant-details/tree.jpg";
import BreadCrumbs from "../layout/BreadCrumbs";
import { Wrapper } from "../../css/page";

const PlantDetailsPage = () => {
	const { id } = useParams();

	const [selectedPlant, setSelectedPlant] = useState(null);

	const fetcherOne = async (id) => {
		console.log("fetch loop");
		console.log(id);
		//const urlOne = `https://theherbalizer-backend.onrender.com/search/${id}`;
		//const urlOne = `http://185.167.96.251/search/${id}`;
		//const urlOne = `http://localhost:8080/search/${id}`;
		const urlOne = `https://theherbalizer-node.hadley-it.dev/search/${id}`;
		try {
			const res = await axios.get(urlOne);
			console.log(res.data);
			setSelectedPlant(res.data);
		} catch (error) {}
	};

	useEffect(() => {
		fetcherOne(id);
	}, []);

	console.log(id);
	console.log(selectedPlant);

	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header-img" className="header-image" />
			</div>
			<BreadCrumbs />
			<div className="body-container">{selectedPlant && <PlantDetails plant={selectedPlant} />}</div>
		</Wrapper>
	);
};

export default PlantDetailsPage;
