const citrusPageData = [
	{
		title: `Citrus japonica`,
		innerLink: `/search/citrus-japonica`,
		url: `https://upload.wikimedia.org/wikipedia/commons/f/f4/Citrus_japonica.jpg`,
		text: `Citrus japonica, the round kumquat, Marumi kumquat, or Morgani kumquat, is a species of citrus fruit in the genus Citrus. It was first described by Carl Peter Thunberg in 1780 as Fortunella japonica. Citrus japonica is a native species in southern China.`,
	},
	{
		title: `Citrus aurantium`,
		innerLink: `/search/citrus-x-aurantium`,
		url: `https://upload.wikimedia.org/wikipedia/commons/2/2e/Citrus_aurantium.jpg`,
		text: `Bitter orange, sour orange, Seville orange, bigarade orange, or marmalade orange is in a narrow sense the citrus tree Citrus × aurantium and its fruit. It is native to Southeast Asia and has been spread by humans to many parts of the world. Many varieties of bitter orange are used for their essential oil, and are found in perfume, used as a flavoring or as a solvent, and also for consumption. The Seville orange variety is used in the production of marmalade and also used to make French bigarade. Bitter orange is also employed in herbal medicine as a stimulant and appetite suppressant, due to its active ingredient, synephrine.`,
	},
	{
		title: `Citrus medica`,
		innerLink: `/search/citrus-medica`,
		url: `https://upload.wikimedia.org/wikipedia/commons/e/ef/Chinesische_Zedrat_Zitrone.jpg`,
		text: `The citron (Citrus medica), historically cedrate, is a large fragrant citrus fruit with a thick rind. It is said to resemble a 'huge, rough lemon'. It is one of the original citrus fruits from which all other citrus types developed through natural hybrid speciation or artificial hybridization. Though citron cultivars take on a wide variety of physical forms, they are all closely related genetically. It is used in Asian and Mediterranean cuisine, traditional medicines, perfume, and religious rituals and offerings.`,
	},
];

const rosesPageData = [
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/5/56/Rose_Black_Baccara_%E3%83%90%E3%83%A9_%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF_%E3%83%90%E3%82%AB%E3%83%A9_%285693278972%29.jpg`,
		title: `Rose Black Baccara`,
		text: `Rosa 'Black Baccara' (aka 'MEIdebenne') is a dark burgundy Hybrid tea rose cultivar, bred by French rose hybridizer, Jacques Mouchotte, before 2000. Meilland International introduced the rose in France in 2000.'Black Baccara' was developed by Jacques Mouchotte before 2000 by crossing the two dark red Hybrid teas, 'Celica' and 'Fuego Negro'. The rose cultivar was introduced in France by Meilland International in 2000. `,
	},
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/d/d0/Rosa_sp.90.jpg`,
		title: "Elina",
		text: `Rosa 'Elina' (AKA 'DICjana') is a light yellow hybrid tea rose bred by Patrick Dickson of Northern Ireland in 1983. The variety was developed from the white floribunda 'Nana Mouskouri' (Dickson, 1975) and the apricot hybrid tea 'Lolita' (Kordes, 1972).`,
	},
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/6/6f/Rose_Peace.jpg`,
		title: `Madame Antoine Meilland`,
		text: "The Peace rose, formally Rosa 'Madame A. Meilland', is a well-known and successful garden rose. It was developed by French horticulturist Francis Meilland, in the years 1935 to 1939. When Meilland foresaw the German invasion of France, he sent cuttings to friends in Italy, Turkey, Germany, and the United States to protect the new rose. It is said that it was sent to the US on the last plane available before the German invasion, where it was safely propagated by the Conard Pyle Co. during the war.",
	},
];

const carnivoresPageData = [
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/e/e2/Dionaea_muscipula_1.jpg`,
		title: `Dionaea muscipula`,
		innerLink: `/search/dionaea-muscipula`,
		text: `The Venus flytrap (Dionaea muscipula) is a carnivorous plant native to the temperate and subtropical wetlands of North America. Dionaea catches its prey—chiefly insects and arachnids—with a "jaw"-like clamping structure, which is formed by the terminal portion of each of the plant's leaves; when an insect makes contact with the open leaves, vibrations from the prey's movements ultimately trigger the "jaws" to shut via tiny hairs on their inner surfaces.`,
	},
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/b/b5/N.northiana%2C_upper_pitcher.jpg`,
		title: `Nepenthes northiana`,
		innerLink: `/search/nepenthes-northiana`,
		text: `Nepenthes northiana, or Miss North's pitcher-plant,is a tropical pitcher plant endemic to Borneo. A tropical carnivorous plant par excellence, Nepenthes northiana is made up of lianas, conical leaves and urns that can reach 43 centimeters. When an insect enters an urn, it can no longer get out because of the wax that covers the walls.`,
	},
	{
		url: `https://upload.wikimedia.org/wikipedia/commons/2/2a/Darlingtonia_californica_%28Jardin_des_plantes%29.jpg`,
		title: `Darlingtonia californica`,
		innerLink: `/search/darlingtonia-californica`,
		text: `The cobra plant (Darlingtonia californica) is a species of flowering plant in the Sarraceniaceae family. It is the only species in the genus "Darlingtonia". Its erect snake shape is reminiscent of a cobra in an intimidating position. This is why it is given the nickname “cobra plant”.

		This carnivorous plant is native to Oregon and northern California. It grows on serpentine soil in marshes with sphagnum moss in a cool and humid temperate climate, but with large temperature variations.`,
	},
];

const sortOptions = [
	{
		label: "no sort",
		value: null,
	},
	{
		label: "year",
		value: "asc",
	},
	{
		label: "year",
		value: "desc",
	},
	// {
	// 	label: "family",
	// 	value: "asc",
	// },
	// {
	// 	label: "family",
	// 	value: "desc",
	// },
	{
		label: "common_name",
		value: "asc",
	},
	{
		label: "common_name",
		value: "desc",
	},
];

const filterOptions = [
	{
		label: "no filter",
		value: null,
	},
	{
		label: "has edible parts",
		value: "edible_parts",
	},
	{
		label: "is a vegetable",
		value: "vegetable",
	},
];

const toxicityOptions = [
	{
		label: "none",
		value: "none",
	},
	{
		label: "low",
		value: "low",
	},
	{
		label: "medium",
		value: "medium",
	},
	{
		label: "high",
		value: "high",
	},
];

const ligneoustypeOptions = [
	{
		label: "none",
		value: "none",
	},
	{
		label: "liana",
		value: "liana",
	},
	{
		label: "subshrub",
		value: "subshrub",
	},
	{
		label: "shrub",
		value: "shrub",
	},
	{
		label: "tree",
		value: "tree",
	},
	{
		label: "parasite",
		value: "parasite",
	},
];

export { rosesPageData, carnivoresPageData, sortOptions, filterOptions, toxicityOptions, ligneoustypeOptions, citrusPageData };
