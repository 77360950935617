import React from "react";
import styled from "styled-components";
import { FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const Wrapper = styled.section`
	font-size: 2rem;
	background-color: var(--green-8);
	display: flex;
	justify-content: center;
	background: linear-gradient(90deg, var(--blue-5) 0%, var(--green-5) 100%);
	.center-container {
		margin: 5rem auto;
		/* border: 1px solid red; */
		max-width: 80%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		color: var(--gold-2);
		a {
			display: flex;
			align-items: center;
			gap: 2rem;
			color: inherit;
			cursor: pointer;
		}
		.social-nets {
			display: flex;
			gap: 3rem;
			.icon:hover {
				color: var(--blue-8);
				transform: scale(1.2);
			}
		}
	}
	@media screen and (max-width: 460px) {
		font-size: 1.6rem;
	}
`;

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<Wrapper>
			<div className="center-container">
				<a>
					<MdOutlineLocationOn />
					Arnhem, Netherlands
				</a>
				<a href="mailto:h.videlier@gmail.com">
					<FiMail />
					h.videlier@gmail.com
				</a>
				<a href="tel:0033603045977">
					<BsTelephone />
					+33 6 03 04 59 77
				</a>
				<div className="social-nets">
					<a href="https://www.linkedin.com/in/hadley-videlier/" target="_blank" className="text-container">
						<BsLinkedin className="icon" />
					</a>
					<a href="https://github.com/Adlax" target="_blank" className="text-container">
						<FaGithub className="icon" />
					</a>
				</div>
				<p>{year}</p>
			</div>
		</Wrapper>
	);
};

export default Footer;
