import styled from "styled-components";

export const Wrapper = styled.section`
	.header-image-container {
		/* border: 2px solid black; */
		width: 100%;
		height: 40vh;
		.header-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	.body-container {
		/* border: 2px solid red; */
		padding: 5rem;
		width: 80vw;
		margin: 0 auto;

		.central-grid {
			/* border: 2px solid violet; */
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			gap: 5rem;

			.cell {
				display: flex;
				width: 100%;
				/* grid-template-columns: repeat(10, 8vw);
				grid-auto-rows: minmax(350px, 400px); */
				/* grid-auto-rows: 1fr; */
				margin-bottom: 4rem;
				gap: 1rem;

				.cell-text {
					font-size: 2.5rem;
					/* border: 2px solid orange; */
					width: 60%;
				}

				.cell-image {
					position: relative;
					width: 40%;
					/* border: 2px solid orange; */
					cursor: pointer;
					overflow: hidden;
					.img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						transition: all 0.2s ease-in-out;
						&:hover {
							transform: scale(1.1);
						}
					}
					.img-title {
						position: absolute;
						bottom: 0;
						right: 1rem;
						font-size: 2rem;
						font-style: italic;
						color: var(--gold-3);
					}
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.body-container {
			.cell {
				.cell-text {
					font-size: 2.2rem !important;
				}
			}
		}
	}
	@media screen and (max-width: 1000px) {
		.body-container {
			.cell {
				flex-direction: column;
				.cell-text {
					font-size: 2rem !important;
					width: 100% !important;
				}
				.cell-image {
					width: 100% !important;
					height: 500px;
				}
			}
		}
	}
	@media screen and (max-width: 800px) {
		.body-container {
			.cell {
				.cell-text {
					font-size: 1.7rem !important;
				}
				.cell-image {
					height: 350px;
				}
			}
		}
	}
	@media screen and (max-width: 650px) {
		.body-container {
			width: 100%;
			.central-grid {
				gap: 5rem;
				.cell {
					display: flex;
					flex-direction: column;
					gap: 3rem;
				}
			}
		}
	}
`;
