import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import headerImg from "../../images/landing/dandelion.jpg";
import BreadCrumbs from "../layout/BreadCrumbs";
import PlantCard from "../sub-components/PlantCard";
import { CiSearch } from "react-icons/ci";
import { HiOutlineChevronRight, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronDoubleLeft } from "react-icons/hi";
import axios from "axios";
import { IoMdCloseCircleOutline } from "react-icons/io";
import PlantDetails from "../sub-components/PlantDetails";
import { useNavigate } from "react-router-dom";
import { filterOptions, sortOptions, toxicityOptions, ligneoustypeOptions } from "../../data";
import { IoMdClose } from "react-icons/io";

const Wrapper = styled.section`
	font-size: 3rem;
	position: relative;
	.header-image-container {
		/* border: 2px solid black; */
		width: 100%;
		height: 40vh;
		.header-image {
			height: 100%;
			width: 100%;
			object-fit: cover;
			/* object-position: 0 -55rem; */
		}
	}
	.body-container {
		/* border: 2px solid red; */
		padding: 5rem;
		font-size: inherit;

		.central-container {
			/* border: 2px solid violet; */
			max-width: 1400px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			padding: 2rem;
			font-size: inherit;

			.isError,
			.isFetching {
				height: 30vh;
			}

			form {
				/* border: 1px solid pink; */
				display: flex;
				/* flex-direction: column; */
				margin-bottom: 4rem;
				.form-group {
					/* border: 2px solid yellow; */
					display: flex;
					align-items: center;
					gap: 2rem;
					.label {
					}
					.input {
						/* margin-top: 0.5rem; */
						padding: 1rem 2rem;
						color: inherit;
						font-size: inherit;
						border: 2px solid rgba(20, 20, 20, 0.5);
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
						outline: none;
						width: 40vw;
						height: 100%;
					}
				}
				button {
					/* height: 58px; */
					font-size: inherit;
					display: grid;
					place-content: center;
					background: var(--green-5);
					border-top-right-radius: 8px;
					border-bottom-right-radius: 8px;
					border-top: 2px solid rgba(20, 20, 20, 0.5);
					border-right: 2px solid rgba(20, 20, 20, 0.5);
					border-bottom: 2px solid rgba(20, 20, 20, 0.5);
					border-left: none;
					cursor: pointer;
					padding: 0 2rem;
					transition: all 0.3s ease-in-out;
					.icon {
						color: var(--gold-3);
						font-size: inherit;
						transition: all 0.3s ease-in-out;
						/* padding: 1rem 2rem; */
					}
					&:hover {
						background: var(--blue-5);
						.icon {
							color: var(--gold-6);
							transform: scale(1.2);
						}
					}
				}
			}

			.sort-filter-container {
				/* border: 2px solid orange; */
				margin-bottom: 4rem;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				/* grid-template-columns: 1fr 1fr 1fr; */
				gap: 4rem;
				font-size: inherit;
				.form-group {
					display: flex;
					/* flex-shrink: 1;
					flex-grow: 1; */
					align-items: center;
					gap: 2rem;
					/* border: 2px solid pink; */
					font-size: inherit;
					.select {
						width: fit-content;
						padding: 1rem 2rem;
						color: inherit;
						font-size: inherit;
						border: 2px solid rgba(20, 20, 20, 0.5);
						border-radius: 8px;
						outline: none;
						.option {
							font-size: inherit !important;
						}
					}
					.checkbox {
						width: 3rem;
						height: 3rem;
					}
				}
			}

			.grid {
				/* border: 2px solid green; */
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(250px, 350px));
				grid-auto-rows: minmax(250px, 350px);
				gap: 1rem;
				justify-content: center;
				.click-layer {
					overflow: hidden;
					/* border: 5px solid red; */
				}
			}

			.hits-sort-filter-info-box {
				margin: 2rem 0;
				.hits-sort-filter-info-box-span {
					color: var(--gold-5);
				}
			}

			.no-hits-container {
				/* border: 2px solid red; */
				display: flex;
				align-items: center;
				gap: 3rem;
				justify-content: space-between;
				.no-hits-text {
				}
				.no-hits-btn-container {
					display: flex;
					.no-hits-btn {
						font-size: inherit;
						display: grid;
						place-content: center;
						background: var(--green-5);
						border-radius: 8px;
						cursor: pointer;
						padding: 0 2rem;
						transition: all 0.3s ease-in-out;
						.no-hits-icon {
							color: var(--gold-3);
							font-size: inherit;
							transition: all 0.3s ease-in-out;
							/* padding: 1rem 2rem; */
						}
						&:hover {
							background: var(--blue-5);
							.icon {
								color: var(--gold-6);
								transform: scale(1.2);
							}
						}
					}
				}
			}
		}
		.pagination-btns-container {
			/* border: 2px solid violet; */
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
			.icon {
				cursor: pointer;
			}
		}
	}
	@media screen and (max-width: 600px) {
		font-size: 2rem;
	}
	@media screen and (max-width: 400px) {
		.body-container {
			.pagination-btns-container {
				font-size: 1.2rem;
			}
		}
	}
`;

const SearchPage = () => {
	const [results, setResults] = useState();
	const [searchterm, setSearchterm] = useState("lemon");
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState(null);
	const [edible, setEdible] = useState(null);
	const [vegetable, setVegetable] = useState(null);
	const [toxicity, setToxicity] = useState("none");
	const [ligneoustype, setLigneoustype] = useState("none");
	const [isFetching, setIsFetching] = useState(false);
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();
	const formRef = useRef();

	const fetcher = async (dataset) => {
		console.log("fetch loop");
		setIsFetching(true);
		console.log(dataset);
		const { q, page, sort, edible, vegetable, toxicity, ligneoustype } = dataset;
		// const url = `http://localhost:8080/search`;
		//const url = `https://theherbalizer-backend.onrender.com/search`;
		//const url = `http://185.167.96.251/search`;
		const url = `https://theherbalizer-node.hadley-it.dev/search`;
		// console.log(url);
		try {
			const res = await axios.post(url, { data: { q, page, sort, edible, vegetable, toxicity, ligneoustype } });
			console.log(res.data);
			setIsFetching(false);
			setIsError(false);
			setResults(res.data);
		} catch (error) {
			setIsFetching(false);
			setIsError(true);
		}
	};

	const handleSubmit = (evt) => {
		evt.preventDefault();
		const formData = new FormData(formRef.current);
		const data = {
			q: formData.get("q"),
			sort: formData.get("sort"),
		};
		console.log(data);
		setPage(1);
		setSearchterm(data.q);
	};

	const handleSort = (evt) => {
		const valueArray = evt.target.value.split(" ");
		// console.log(evt.target.value);
		// console.log(valueArray);
		setSort({ label: valueArray[0], value: valueArray[1] });
	};

	useEffect(() => {
		document.getElementById("header-nav").scrollIntoView({ behavior: "instant" });
	}, []);

	useEffect(() => {
		const data = {
			q: searchterm,
			page: page,
			sort: sort,
			edible,
			vegetable,
			toxicity,
			ligneoustype,
		};
		fetcher(data);
	}, [searchterm, page, sort, edible, vegetable, toxicity, ligneoustype]);

	const getMaxPage = () => {
		let max = null;
		if (results?.meta?.total % 20 === 0) {
			max = results.meta.total / 20;
		} else {
			max = Math.floor(results?.meta?.total / 20) + 1;
		}
		// console.log(max);
		return max;
	};

	const increasePage = () => {
		const maxPage = getMaxPage();
		if (page < maxPage) {
			setPage((oldState) => oldState + 1);
		}
	};

	const decreasePage = () => {
		if (page > 1) {
			setPage((oldState) => oldState - 1);
		}
	};

	const handlePlantSelection = async (evt) => {
		const id = evt.currentTarget.dataset.id;
		navigate(`${id}`);
	};

	const clearFilters = () => {
		// page, sort, edible, vegetable, toxicity, ligneoustype
		setPage(1);
		setSort(null);
		setEdible(null);
		setVegetable(null);
		setToxicity("none");
		setLigneoustype("none");
	};

	console.log(isFetching);
	console.log(isError);

	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header" className="header-image" />
			</div>
			<BreadCrumbs />
			<div className="body-container">
				<div className="central-container">
					<form ref={formRef} onSubmit={handleSubmit}>
						<div className="form-group">
							<label className="label" htmlFor="q">
								Term
							</label>
							<input className="input" type="text" name="q" id="q" placeholder="ex: lemon" />
						</div>
						<button type="submit">
							<CiSearch className="icon" />
						</button>
					</form>
					{isFetching ? <div className="isFetching">Fetching data...</div> : null}
					{!isFetching && isError ? <div className="isError">Error fetching infos. Try again in a moment.</div> : null}
					{results?.meta?.total === 0 && (
						<div className="no-hits-container">
							<p className="no-hits-text">No hits.</p>
							<div className="no-hits-btn-container">
								Clear results:
								<button onClick={clearFilters} className="no-hits-btn">
									<IoMdClose className="no-hits-icon" />
								</button>
							</div>
						</div>
					)}
					{results && results?.meta?.total !== 0 && (
						<>
							<div className="sort-filter-container">
								<div className="form-group">
									<label htmlFor="sort">Sort</label>
									<select id="sort" name="sort" className="select" onChange={handleSort}>
										{sortOptions.map((option) => (
											<option value={`${option.label} ${option.value}`} className="option">
												{option.label} {option.value}
											</option>
										))}
									</select>
								</div>
								<div className="form-group">
									<label htmlFor="edible">Edible</label>
									<input
										type="checkbox"
										name="edible"
										id="edible"
										className="checkbox"
										onClick={(evt) => setEdible(evt.target.checked)}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="vegetable">Vegetable</label>
									<input
										type="checkbox"
										name="vegetable"
										id="vegetable"
										className="checkbox"
										onClick={(evt) => setVegetable(evt.target.checked)}
									/>
								</div>
								{/* <div className="form-group">
									<label htmlFor="toxicity">Toxicity</label>
									<select name="toxicity" id="toxicity" className="select" onChange={(evt) => setToxicity(evt.target.value)}>
										{toxicityOptions.map((option) => (
											<option value={option.value} className="option">
												{option.label}
											</option>
										))}
									</select>
								</div> */}
								{/* <div className="form-group">
									<label htmlFor="ligneous_type">Ligneous Type</label>
									<select
										name="ligneous_type"
										id="ligneous_type"
										className="select"
										onChange={(evt) => setLigneoustype(evt.target.value)}
									>
										{ligneoustypeOptions.map((option) => (
											<option value={option.value} className="option">
												{option.label}
											</option>
										))}
									</select>
								</div> */}
							</div>
							<div className="hits-sort-filter-info-box">
								{results?.meta?.total} hits with critera term "<span className="hits-sort-filter-info-box-span">{searchterm}</span>"{" "}
								<span className="hits-sort-filter-info-box-span">{edible ? ", edible" : null}</span>
								<span className="hits-sort-filter-info-box-span">{vegetable ? `, vegetable` : null}</span>
								<span className="hits-sort-filter-info-box-span">{ligneoustype !== "none" ? `, ${ligneoustype}` : null}</span> :
							</div>
							<div className="grid">
								{results?.data?.map((obj) => (
									<div className="click-layer" data-id={obj.slug} key={obj.id} onClick={handlePlantSelection}>
										<PlantCard obj={obj} />
									</div>
								))}
							</div>
							<div className="pagination-btns-container">
								<HiOutlineChevronDoubleLeft className="icon" onClick={() => setPage(1)} />
								<HiOutlineChevronLeft className="icon" onClick={decreasePage} />
								<div style={{ textDecoration: "underline" }}>{page}</div>
								<div> / {getMaxPage()}</div>
								<HiOutlineChevronRight className="icon" onClick={increasePage} />
								<HiOutlineChevronDoubleRight className="icon" onClick={() => setPage(getMaxPage())} />
							</div>
						</>
					)}
				</div>
			</div>
		</Wrapper>
	);
};

export default SearchPage;
