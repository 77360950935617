import React from "react";
import { Wrapper } from "../../css/DisclaimerAndIntroStyle";

const IntroText = () => {
	return (
		<Wrapper>
			<p className="text">
				This site offers access to a database of nearly half a million plant references. If you want to have information about any of them, query the search
				engine by clicking on the "Search" button a little below.
			</p>
		</Wrapper>
	);
};

export default IntroText;
