import React from "react";
import styled from "styled-components";
import missingImage from "../../images/pages/search/no-image-svgrepo-com.svg";

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	overflow: hidden;
	&:hover {
		.plant-img {
			transform: scale(1.1);
		}
	}
	.plant-img-container {
		width: 100%;
		height: 75%;
		/* border: 5px solid yellow; */
		overflow: hidden;
		.plant-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: all 0.3s ease;
		}
	}
	.plant-text-container {
		text-align: center;
	}
`;

const PlantCard = ({ obj }) => {
	// console.log(obj);
	// const { common_name, image_url } = obj;
	const { common_name, image_url } = obj;
	return (
		<Wrapper>
			<div className="plant-img-container">
				<img src={image_url || missingImage} alt="plant-img" className="plant-img" />
			</div>
			<div className="plant-text-container">
				<p className="plant-text">{obj.common_name || obj.slug || obj.scientific_name}</p>
			</div>
		</Wrapper>
	);
};

export default PlantCard;
