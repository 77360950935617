import React from "react";
import styled from "styled-components";
import { FaBookReader } from "react-icons/fa";
import { GiFlowerTwirl } from "react-icons/gi";
import { GiPlantWatering } from "react-icons/gi";
import { FaMapMarkedAlt } from "react-icons/fa";
import { LuBookCopy } from "react-icons/lu";

const Wrapper = styled.div`
	font-size: 3rem;
	max-width: 85%;
	/* border: 5px solid green; */
	margin: 2rem auto;
	height: fit-content;
	.bottom-spacer {
		margin-bottom: 5rem;
	}
	.inter-spacer {
		margin-bottom: 2rem;
	}
	.container {
		font-size: 2rem;
	}
	.breadcrumbs-container {
		margin: 2rem auto;
		margin-bottom: 2rem;
	}
	.top-container {
		display: flex;
		gap: 2rem;
		padding-bottom: 2rem;
		border-bottom: 2px solid gray;
		/* border: 4px solid red; */
		.img-container {
			width: 50%;
			height: 30rem;
			overflow: hidden;
			.main-image {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.text-container {
			.main-title {
				font-size: 8rem;
			}
			.second-title {
			}
		}
	}
	.specs-container {
		.specs-grid {
		}
	}
	.growing-container {
	}
	.distros-container {
		.distro-native {
		}
	}
	.synonyms-container {
		ul {
			margin-left: 2.5rem;
			li {
				margin-bottom: 0.5rem;
			}
		}
	}
	.section-title {
		font-weight: bold;
		font-size: 3.5rem;
		margin-bottom: 1rem;
	}
	.subsection-title {
		font-weight: bold;
	}
	.section-pseudo-title {
		margin-bottom: 1rem;
	}
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0.5rem;
	}
	.no-bold {
		font-weight: normal;
	}
	.icon {
		color: var(--blue-5);
	}
	@media screen and (max-width: 1400px) {
		.top-container {
			.text-container {
				.main-title {
					font-size: 6rem;
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.top-container {
			.text-container {
				.main-title {
					font-size: 4rem;
				}
				.second-title {
					font-size: 2.5rem;
				}
			}
		}
	}
	@media screen and (max-width: 800px) {
		.top-container {
			.text-container {
				.main-title {
					font-size: 3rem;
				}
				.second-title {
					font-size: 2rem;
				}
			}
		}
	}
	@media screen and (max-width: 650px) {
		.breadcrumbs-container {
			font-size: 2.5rem;
		}
		.grid {
			grid-template-columns: 1fr;
		}
		.top-container {
			flex-direction: column;
			.img-container {
				width: 100%;
			}
		}
		.section-title {
			font-size: 3rem;
		}
	}
	@media screen and (max-width: 500px) {
		.breadcrumbs-container {
			font-size: 1.8rem;
		}
		.section-title {
			font-size: 2.5rem;
		}
	}
	@media screen and (max-width: 400px) {
		.section-title {
			font-size: 2.1rem;
		}
	}
`;

const PlantDetails = ({ plant }) => {
	console.log(plant);
	const { image_url, scientific_name, common_name, family, genus, main_species, author, bibliography, observations } = plant?.data;
	const { specifications, duration, edible, edible_part, vegetable, flower, foliage, fruit_or_seed, images, distribution, synonyms, growth } = main_species;

	return (
		<Wrapper>
			<div className="breadcrumbs-container">
				{family.name} / {genus.name} / {scientific_name}
			</div>
			<div className="top-container">
				<div className="img-container">
					<img src={image_url} alt="main-img" className="main-image" />
				</div>
				<div className="text-container">
					<p className="main-title">{scientific_name}</p>
					<p className="second-title">{common_name}</p>
					<p className="second-title">
						<FaBookReader className="icon" /> {author}
						{bibliography}
					</p>
				</div>
			</div>
			<div className="container specs-container bottom-spacer">
				<p className="section-title">
					<GiFlowerTwirl className="icon" /> Specifications
				</p>
				<p className="section-pseudo-title">{observations}</p>
				<div className="specs-grid grid">
					<div className="subsection-title">
						Height: <span className="no-bold">{specifications.average_height.cm || "unknown"} cm</span>
					</div>
					<div className="subsection-title">
						Growth habit: <span className="no-bold">{specifications.growth_habit || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Ligneous type: <span className="no-bold">{specifications.ligneous_type || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Toxicity: <span className="no-bold">{specifications.toxicity || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Duration: <span className="no-bold">{duration || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Edible: <span className="no-bold">{edible ? "yes" : "no" || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Edible part(s): <span className="no-bold">{edible_part || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Flower: <span className="no-bold">{flower.color || "unknown"}</span>
						<span className="no-bold"> {flower.conspicuous || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Foliage: <span className="no-bold">{foliage.color || "unknown"}</span>
						<span className="no-bold"> {foliage.leaf_retention || "unknown"}</span>
						<span className="no-bold"> {foliage.texture || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Fruit/Seed: <span className="no-bold">{fruit_or_seed.color || "unknown"}</span>
						<span className="no-bold"> {fruit_or_seed.conspicuous || "unknown"}</span>
						<span className="no-bold"> {fruit_or_seed.seed_persistence || "unknown"}</span>
						<span className="no-bold"> {fruit_or_seed.shape || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Vegetable: <span className="no-bold">{vegetable ? "yes" : "no" || "unknown"}</span>
					</div>
				</div>
			</div>
			<div className="container growing-container bottom-spacer">
				<p className="section-title">
					<GiPlantWatering className="icon" /> Growing
				</p>
				<div className="growing-grid grid inter-spacer">
					<div className="subsection-title">
						Light: <span className="no-bold">{growth.light || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Atm humidity: <span className="no-bold">{growth.atmospheric_humidity || "unknown"} %</span>
					</div>
					<div className="subsection-title">
						Ph:&nbsp;
						<span className="no-bold">
							Between {growth.ph_minimum || "unknown"} and {growth.ph_maximum || "unknown"}
						</span>
					</div>
					<div className="subsection-title">
						Precipitations:&nbsp;
						<span className="no-bold">
							Between {growth.minimum_precipitation.mm || "unknown"} mm and {growth.maximum_precipitation.mm || "unknown"} mm
						</span>
					</div>
					<div className="subsection-title">
						Temperature:&nbsp;
						<span className="no-bold">
							Between {growth.minimum_temperature.deg_c || "unknown"} &#xb0;C and {growth.maximum_temperature.deg_c || "unknown"} &#xb0;C
						</span>
					</div>
				</div>
				<div className="soil-grid grid">
					<div className="subsection-title">
						Soil humidity: <span className="no-bold">{growth.soil_humidity || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Soil nutriments: <span className="no-bold">{growth.soil_nutriments || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Soil salinity: <span className="no-bold">{growth.soil_salinity || "unknown"}</span>
					</div>
					<div className="subsection-title">
						Soil texture: <span className="no-bold">{growth.soil_texture || "unknown"}</span>
					</div>
				</div>
				<div className="calendar-grid"></div>
			</div>
			<div className="container distros-container bottom-spacer">
				<p className="section-title">
					<FaMapMarkedAlt className="icon" /> Distributions
				</p>
				<div className="distro-native">
					<span className="subsection-title">Native: </span>
					{distribution?.native?.map((dist, index) => {
						if (index !== distribution?.native?.length - 1) {
							return <span>{dist}, </span>;
						} else {
							return <span>{dist}</span>;
						}
					}) || "unknown"}
				</div>
				<div className="inter-spacer"></div>
				<div className="distro-introduced">
					<span className="subsection-title">Introduced: </span>
					{distribution?.introduced?.map((dist, index) => {
						if (index !== distribution?.introduced?.length - 1) {
							return <span>{dist}, </span>;
						} else {
							return <span>{dist}</span>;
						}
					}) || "unknown"}
				</div>
			</div>
			<div className="container synonyms-container bottom-spacer">
				<p className="section-title">
					<LuBookCopy className="icon" /> Synonyms
				</p>
				<ul>
					{main_species?.synonyms?.map((syn) => (
						<li>
							{syn.name}
							{syn.author}
						</li>
					))}
				</ul>
			</div>
		</Wrapper>
	);
};

export default PlantDetails;
