import React from "react";
import styled from "styled-components";
import logo from "../../images/landing/rose-illustration-4-svgrepo-com.svg";

const Wrapper = styled.section`
	width: 100%;
	height: 7vh;
	font-size: 3rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	background: linear-gradient(90deg, var(--blue-5) 0%, var(--green-5) 100%);
	opacity: 0.8;
	backdrop-filter: blur(20px);
	/* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
	box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
	z-index: 10;
	transition: all 0.3s ease-in-out;
	&.hide {
		opacity: 0;
	}
	.left-part {
		display: flex;
		align-items: center;
		/* border: 1px solid red; */
		.logo-container {
			height: 4rem;
			width: 4rem;
			margin-left: 2rem;
			margin-right: 2rem;
			cursor: pointer;
			.logo {
				width: 100%;
				height: 100%;
				transition: all 0.2s ease-in-out;
				&:hover {
					transform: scale(1.2);
				}
			}
		}
		.title-container {
			color: var(--gold-2);
		}
	}
`;

const HeaderNav = () => {
	let prevScrollPos = window.screenY;

	// console.log(prevScrollPos);

	window.addEventListener("scroll", () => {
		const currentScrollPos = window.scrollY;
		// console.log(currentScrollPos);
		const wrapperNav = document.getElementById("wrapperNav");
		if (currentScrollPos < prevScrollPos) {
			// console.log("show");
			wrapperNav.classList.remove("hide");
		} else {
			// console.log("hide");
			wrapperNav.classList.add("hide");
		}
		prevScrollPos = currentScrollPos;
	});

	return (
		<Wrapper id="wrapperNav">
			<div className="left-part">
				<div className="logo-container">
					<a href="/">
						<img src={logo} alt="logo" className="logo" />
					</a>
				</div>
				<div className="title-container">
					<p>The Herbalizer_</p>
				</div>
			</div>
		</Wrapper>
	);
};

export default HeaderNav;
