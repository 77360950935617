import React, { useEffect } from "react";
import headerImg from "../../images/pages/citrus/oranges.jpg";
import { citrusPageData } from "../../data";
import BreadCrumbs from "../layout/BreadCrumbs";
import { Wrapper } from "../../css/page";
import { useNavigate } from "react-router-dom";

const CitrusPage = () => {
	const navigate = useNavigate();

	const handleNavigate = (target) => {
		console.log("Navigate");
		navigate(target);
	};

	useEffect(() => {
		document.getElementById("header-nav").scrollIntoView({ behavior: "instant" });
	}, []);

	// const boldingTextMatcher = (match, text) => {
	// 	let regExp = new RegExp();
	// 	regExp = new RegExp(match, "gi");
	// 	return text.replace(regExp, <strong>${match}</strong>);
	// };

	return (
		<Wrapper>
			<div className="header-image-container">
				<img src={headerImg} alt="header" className="header-image" />
			</div>
			<BreadCrumbs />
			<div className="body-container">
				<div className="central-grid">
					<div className="cell cell1">
						<div className="cell-text">{citrusPageData[0].text}</div>
						<div className="cell-image" onClick={() => handleNavigate(citrusPageData[0].innerLink)}>
							<img src={citrusPageData[0].url} alt="" className="img" />
							<p className="img-title">{citrusPageData[0].title}</p>
						</div>
					</div>
					<div className="cell cell2">
						<div className="cell-image" onClick={() => handleNavigate(citrusPageData[1].innerLink)}>
							<img src={citrusPageData[1].url} alt="" className="img" />
							<p className="img-title">{citrusPageData[1].title}</p>
						</div>
						<div className="cell-text">{citrusPageData[1].text}</div>
					</div>
					<div className="cell cell3">
						<div className="cell-text">{citrusPageData[2].text}</div>
						<div className="cell-image" onClick={() => handleNavigate(citrusPageData[2].innerLink)}>
							<img src={citrusPageData[2].url} alt="" className="img" />
							<p className="img-title">{citrusPageData[2].title}</p>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default CitrusPage;
