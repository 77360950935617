import "./App.css";
import { RouterProvider, createBrowserRouter, createHashRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Landing from "./components/landing/Landing";
// import Roses from "./components/pages/Roses";
import RosesPage from "./components/pages/RosesPage";
import CarnivorousPage from "./components/pages/CarnivorousPage";
import SearchPage from "./components/pages/SearchPage";
import PlantDetailsPage from "./components/pages/PlantDetailsPage";
import CitrusPage from "./components/pages/CitrusPage";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{ index: true, element: <Landing /> },
			{ path: "roses", element: <RosesPage /> },
			{ path: "citrus", element: <CitrusPage /> },
			{ path: "carnivorous", element: <CarnivorousPage /> },
			{ path: "search", element: <SearchPage /> },
			{ path: "search/:id", element: <PlantDetailsPage /> },
		],
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
