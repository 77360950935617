import React from "react";
import styled from "styled-components";
import angusta from "../../images/landing/angusta.jpeg";
import cornus from "../../images/landing/cornus.jpeg";
import pine from "../../images/landing/pine.jpeg";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.section`
	margin-bottom: 10rem;
	width: 100%;
	height: 500px;
	/* border: 2px solid green; */
	display: flex;
	.images-container {
		/* border: 1px solid red; */
		height: 100%;
		width: 50%;
		.center {
			margin: 4rem auto;
			height: 80%;
			width: 80%;
			/* border: 1px dashed blue; */
			position: relative;
			.img-container {
				/* border: 2px solid red; */
				position: absolute;
				overflow: hidden;
				width: 25rem;
				height: 25rem;
				border: 4px solid var(--blue-5);
				border-radius: 6px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					transition: all 0.3s ease-in-out;
					cursor: pointer;
					&:hover {
						transform: scale(1.2);
					}
				}
			}
			.img-container1 {
				top: 5%;
				left: 5%;
			}
			.img-container2 {
				top: 25%;
				left: 25%;
				z-index: 2;
			}
			.img-container3 {
				top: 45%;
				left: 45%;
			}
		}
	}
	.text-container {
		/* border: 1px solid red; */
		height: 100%;
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 3rem;
		.text {
			text-align: center;
			font-size: 4rem;
			max-width: 50rem;
			color: var(--blue-7);
		}
		.search-btn {
			position: relative;
			text-transform: uppercase;
			letter-spacing: 0.5rem;
			padding: 1rem 2rem;
			background-color: var(--blue-5);
			color: var(--gold-4);
			border: 1px solid var(--gold-2);
			font-family: inherit;
			margin: 0 auto;
			cursor: pointer;
			/* border-radius: 6px; */
			transition: 0.5s;
			z-index: 1;
		}
		.search-btn::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: var(--green-7);
			transform-origin: right;
			transition: all 0.5s ease;
			transform: scaleX(0);
			z-index: -1;
			/* border-radius: 6px; */
		}
		.search-btn:hover {
			/* color: var(--gold-7); */
			&::after {
				transform-origin: right;
				transform: scaleX(1);
			}
		}
	}
	@media screen and (max-width: 1000px) {
		.text-container .text {
			font-size: 3rem;
		}
		.images-container {
			.center {
				.img-container {
					width: 20rem;
					height: 20rem;
				}
			}
		}
	}
	@media screen and (max-width: 800px) {
		.text-container .text {
			font-size: 2.5rem;
		}
		.images-container {
			.center {
				.img-container {
					width: 15rem;
					height: 15rem;
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const SearchLander = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/search");
	};

	return (
		<Wrapper>
			<div className="text-container">
				<p className="text">Are you searching for a specific plant ?</p>
				<button className="search-btn" onClick={handleClick}>
					Search
				</button>
			</div>
			<div className="images-container">
				<div className="center">
					<div className="img-container img-container1">
						<img src={pine} alt="" className="img1" />
					</div>
					<div className="img-container img-container2">
						<img src={angusta} alt="" className="img2" />
					</div>
					<div className="img-container img-container3">
						<img src={cornus} alt="" className="img3" />
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default SearchLander;
